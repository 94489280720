html { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; height: calc(100% - 64px); /* 64px = top header complete height */ }


.m-10 {
  margin: 10px !important;
}
.m-5 {
  margin: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.m-0 {
  margin: 0 !important;
}

.btn-block {
  display: block !important;
  width: 100%;
}

.border-bottom-ccc {
  border-bottom: 1px #CCC solid;
}

.actionTabGroup .mat-tab-header {
  background-color: #fff2c6;
  color: #000000;
  text-transform: uppercase;
}

.auction-form {
  padding: 10px;
}

mat-sidenav-container {
  position: fixed;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
}

table {
  --cell-padding: 8px;
  width: 100%;
}

table td.mat-cell, table th.mat-header-cell {
  padding: 0 var(--cell-padding) 0 0;
}

table th.mat-header-cell:first-of-type, table td.mat-cell:first-of-type, table td.mat-footer-cell:first-of-type {
  padding-left: calc( 2 * var(--cell-padding));
}

table th.mat-header-cell:last-of-type, table td.mat-cell:last-of-type, table td.mat-footer-cell:last-of-type {
  padding-right: calc( 2 * var(--cell-padding));
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #f6f6f6;
  color:#000;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color:#000;
}

.fill-space {
  flex: 1 1 auto;
}



.mat-dialog-actions {
  flex-flow: row-reverse;
}

.mat-tab-label {
  opacity: 1 !important;
}


.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #ffd740 !important;
}

.spinner-centered {
  margin: 2rem auto;
}


@media only screen and (min-width: 1280px) {
  .wrapper {
    margin: 15px;
  }
  app-cc {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - var(--toolbar-height) - var(--navigation-height));
    overflow: hidden;
  }

  app-cc > .mat-toolbar {
    flex-shrink: 0;
  }

  app-cc > .wrapper {
    margin-bottom: 0;
    overflow: auto;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
  }
  app-cc > .wrapper > .row {
    overflow: hidden;
    flex-grow: 1;
  }
  app-cc > .wrapper > .row > .column {
    overflow: auto;
    height: calc(100vh - var(--toolbar-height) - var(--navigation-height) - 100px);
  }
  app-cc-next-items {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  app-cc-next-items .mat-toolbar-single-row {
    flex-shrink: 0;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  margin: 5px;
  row-gap: 0.5rem;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  max-width: 100%;
}

.mat-tab-body-wrapper {
  flex: 1 1 100%;
}

.mat-figure {
  align-items: center;
  justify-content: left !important;
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}

.align-middle {
  vertical-align: middle;
}


.mat-mdc-tab, .mat-mdc-tab-header {
  background-color: white !important;
}
